import SlimSelect from "slim-select";

window.addEventListener("load", function () {
  const isDesktop = window.matchMedia("(min-width: 1000px)").matches;
  document.querySelectorAll(".ch24-select").forEach(function (el: HTMLSelectElement) {
    new SlimSelect({
      select: el,
      settings: {
        allowDeselect: true,
        showSearch: el.dataset.showSearch === "true" && isDesktop,
        hideSelected: el.multiple,
        placeholderText: el.dataset.placeholder
      },
      events: {
        searchFilter: (option, search) => {
          return option.text.substring(0, search.length).toLowerCase() === search.toLowerCase()
        }
      }
    });
  });
});
